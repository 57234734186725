import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['nl', 'de', 'en', 'fr']
  static values = { url: String }  

  connect() {
    console.log('Translate loaded');
  }

  en() {
    this.translate('en', this.enTarget.value);
  }

  fr() {
    this.translate('fr', this.frTarget.value);
  }

  de() {
    this.translate('de', this.deTarget.value);
  }

  all() {
    console.log(this.nlTarget.value)
    this.en();
    this.fr();
    this.de();
  }

  translate(to, target) {
    let url = this.urlValue;    
    url += `?from=nl&`;
    url += `to=${to}&`;
    url += `original_text=${escape(this.nlTarget.value)}`;

    fetch(url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.locale == 'en') {
          this.enTarget.value = data.result;
        }

        if (data.locale == 'fr') {
          this.frTarget.value = data.result;
        }

        if (data.locale == 'de') {
          this.deTarget.value = data.result;
        }
      });
  }
}
