import {Controller} from 'stimulus';
import smoothscroll from 'smoothscroll-polyfill';

export default class extends Controller {
  static targets = ['content', 'link', 'focus', 'scrollToOnHide'];

  connect() {
    if (this.hasScrollToOnHideTarget) {
      smoothscroll.polyfill();
    }
  }

  unhide() {
    this.contentTargets.forEach((element) => element.hidden = false);
    this.linkTargets.forEach((element) => element.hidden = true);

    if (this.hasFocusTarget) {
      this.focusTarget.focus();
    }
  }

  hide() {
    this.contentTargets.forEach((element) => element.hidden = true);
    this.linkTargets.forEach((element) => element.hidden = false);
    if (this.hasFocusTarget) {
      this.focusTarget.focus();
    }

    if (this.hasScrollToOnHideTarget) {
      this.scrollToOnHideTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'});
    }
  }
}
