import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['image', 'container'];

  add() {
    const nextImages = this.imageTargets.filter((e) => e.classList.contains('d-none'));
    if (nextImages.length > 0) {
      nextImages[0].classList.remove('d-none');
    }
    if (nextImages.length <= 1) {
      this.containerTarget.classList.add('d-none');
    }
  }
}
