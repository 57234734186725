import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['template', 'list'];

  add() {
    const newRow = this.templateTarget.content.cloneNode(true);
    const ts = new Date().getTime();
    $(newRow).find('input, select, textarea').each(function() {
      $(this).attr('name', function() {
        return $(this).attr('name').replace('new_record', ts);
      });
      if ($(this).attr('id')) {
        $(this).attr('id', function() {
          return $(this).attr('id').replace('new_record', ts);
        });
      }
    });
    $(newRow).find('li').addClass('nestedfield-item');
    this.listTarget.appendChild(newRow);
    event.preventDefault();
  }

  remove() {
    const list = $(event.target).closest('.nestedfield-item');
    list.find('input[name*=_destroy]').val('true');
    list.slideUp(600);
    event.preventDefault();
  }
};
