import {Controller} from 'stimulus';
import smoothscroll from 'smoothscroll-polyfill';

export default class extends Controller {
  static values = {destination: String}

  connect() {
    smoothscroll.polyfill();
  }

  to() {
    this.destination = document.getElementById(this.destinationValue);
    this.destination.scrollIntoView({behavior: 'smooth'});
  }
}
